export default [
  {
    path: '/admin/monitoring-question',
    name: 'monitoring-question',
    meta: { requiresAuth: true },
    component: () => import('@/pages/credit-operation/monitoring-question/index.vue')
  },
  {
    path: '/admin/monitoring-question/create',
    name: 'monitoring-question-create',
    meta: { requiresAuth: true },
    component: () => import('@/pages/credit-operation/monitoring-question/create.vue')
  },
  {
    path: '/admin/monitoring-question/:id/edit',
    name: 'monitoring-question-edit',
    meta: { requiresAuth: true },
    component: () => import('@/pages/credit-operation/monitoring-question/edit.vue'),
  }
]


