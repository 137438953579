import httpClient from "@/http-client";


export const get = (Options = {}) => {
    return httpClient
    .get("/api/monitoring-surveys", Options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data));
};

export const destroy = (id, data) =>{
    return httpClient
        .delete(`/api/monitoring-surveys/${id}`,data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default { get , destroy};
