export default [
  {
    path: '/admin/monitoring-surveys',
    name: 'monitoring-survey',
    meta: { requiresAuth: true },
    component: () => import('@/pages/credit-operation/monitoring-survey/index.vue')
  }
]


