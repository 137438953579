import { baseState, baseMutations } from '@/store/mixins'
import employeeProbation from '@/api/report/employee-probation';

export const state = {
    ...baseState,
    employeeProfiles: []
}

export const mutations = {
    ...baseMutations,
    SET_EMPLOYEE_PROFILES (state, data) {
        state.employeeProfiles = data
    }
}
export const actions = {
    async getEmployeeProfile (context, attributes = {}) {
        let response = await employeeProbation.employee({
            params: Object.assign({}, attributes)
        })
        context.commit('SET_EMPLOYEE_PROFILES', response.data)
    },
    probationPreviewPdf (context, attributes = {}) {
        return employeeProbation.probationPreviewPdf({
            params: Object.assign(
                {
                    template: localStorage.getItem('langCode')
                        ? localStorage.getItem('langCode')
                        : 'en'
                },
                attributes
            )
        })
    },
    exportExcel(context, { ...attributes }) {
        return employeeProbation.exportExcel(Object.assign(
            {
                template: localStorage.getItem('langCode')
                    ? localStorage.getItem('langCode')
                    : 'en'
            },
            attributes
        ))
    }
}
