import httpClient from '@/http-client'

export const employee = data => {
    return httpClient
        .get('/api/reports/employee-probation/employees', data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}

export const probationPreviewPdf = (options = {}) => {
    return httpClient
        .get('/api/reports/employee-probation/pdf', options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data))
}

export const exportExcel = params => {
    return httpClient
        .post('/api/reports/employee-probation/excel', params, {
            responseType: 'arraybuffer'
        })
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error.response.data))
}
export default {
    exportExcel,
    employee,
    probationPreviewPdf
}
