import monitoringQuestion from "@/api/credit-operation/monitoring-question";
import { isEmpty } from "lodash";
import { baseState, baseMutations } from "@/store/mixins";

export const state = {
    ...baseState,
    displayFormats:[],
    questionCategories:[]
};

export const getters = {};

export const mutations = {
    ...baseMutations,
    SET_DISPLAY_FORMAT(state, data) {
        state.displayFormats = data;
    },
    SET_QUESTION_FORMAT(state, data) {
        state.questionCategories = data;
    },
};

export const actions = {
       fetch(context, attrubutes = {}) {
        let params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attrubutes
        );
        return monitoringQuestion.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    async find(context, id) {
        if (isEmpty(context.state.edit_data)) {
            let response = await monitoringQuestion.find(id);
            context.commit("SET_EDIT_DATA", response.data);
        }
    },
    
    store(context, data) {
    return monitoringQuestion.store(data)
     },
    update(context, { id, data }) {
    return monitoringQuestion.update(id, data);
    },
    changeIsActive(context, { id, data }) {
        return monitoringQuestion.changeIsActive(id, data);
    },
    destroy(context, id) {
        return monitoringQuestion.destroy(id);
    },
    async getDisplayFormat(context, attributes = {}) {
        let response = await monitoringQuestion.displayFormats({ params: attributes });
        context.commit("SET_DISPLAY_FORMAT", response.data);
    },
        async getQuestionCategory(context, attributes = {}) {
        let response = await monitoringQuestion.getQuestionCategories({ params: attributes });
        context.commit("SET_QUESTION_FORMAT", response.data);
    },


};
