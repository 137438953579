import httpClient from "@/http-client";


export const get = (Options = {}) => {
    return httpClient
    .get("/api/monitoring-questions", Options)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
  return httpClient
    .post('/api/monitoring-questions', data)
    .then(response => Promise.resolve(response.data))
    .catch(error => Promise.reject(error.response.data))
}

export const find = (id, options = {}) => {
    return httpClient
        .get(`/api/monitoring-questions/${id}`, options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/monitoring-questions/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const destroy = id =>{
    return httpClient
        .delete(`/api/monitoring-questions/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const displayFormats = (Option = {}) => {
    return httpClient
        .get("/api/monitoring-questions/display-format", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const getQuestionCategories = (Option = {}) => {
    return httpClient
        .get("/api/monitoring-questions/get-question-category", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const changeIsActive = (id, data) => {
    return httpClient
        .put(`/api/monitoring-questions/change-is-actives/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};



export default { get, store, displayFormats , getQuestionCategories, find ,update ,destroy,changeIsActive };
