import httpClient from "@/http-client";

export const get = (Options = {}) => {
    return httpClient
        .get("/api/monitoring-question-categories", Options)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const store = data => {
    return httpClient
        .post("/api/monitoring-question-categories", data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const update = (id, data) => {
    return httpClient
        .put(`/api/monitoring-question-categories/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const surveyor = (Option = {}) => {
    return httpClient
        .get("/api/monitoring-question-categories/surveyors", Option)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export const destroy = id =>{
    return httpClient
        .delete(`/api/monitoring-question-categories/${id}`)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};
export const changeIsActive = (id, data) => {
    return httpClient
        .put(`/api/monitoring-question-categories/change-is-actives/${id}`, data)
        .then(response => Promise.resolve(response.data))
        .catch(error => Promise.reject(error.response.data));
};

export default { get, store, surveyor, destroy, update, changeIsActive};
