export default [
  {
    path: '/admin/monitoring-question-category',
    name: 'monitoring-question-category',
    meta: { requiresAuth: true },
    component: () => import('@/pages/credit-operation/monitoring-question-category/index.vue')
  }
]


